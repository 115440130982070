import { reterBreadcrumbs } from 'reter/config/routes';
import { previdenciaBreadcrumbs } from 'previdencia/config/routes';
import { prestamistaBreadcrumbs as contratosPrestamistaBreadcrumbs } from 'contratosPrestamista/config/routes';
import { prestamistaBreadcrumbs } from 'prestamista/config/routes';
import { portabilidadeBreadcrumbs } from 'portabilidade/config/routes';
import { vidaBreadcrumbs } from 'vida/config/routes';
import { ferramentasBreadcrumbs } from 'ferramentas/config/routes';
import { consultaClienteBreadcrumbs } from 'consultaCliente/config/routes';
import { sinistroBreadcrumbs } from 'sinistro/config/routes';
import { relatoriosBreadcrumbs } from 'relatorios/config/routes';
import { extranetBreadcrumbs } from 'extranet/config/routes';
import { atendimentoBreadcrumbs } from 'atendimento/config/routes';
import { registrarOcorrenciasAscBreadcrumbs } from 'registroOcorrenciaASC/config/routes';
import { faturamentoBreadcrumbs } from 'painelInadimplencia/config/routes';
import { painelDPSBreadcrumbs } from 'dps/config/routes';
import { prospeccaoBreadcrumbs } from 'contratosPrestamista/features/preanalise/routes';
import { painelPortabilidadeBreadcrumbs } from 'painelPortabilidade/config/routes';

const breadcrumbs = {
  '/': 'Home',
  '/cliente/:id': null,
  ...reterBreadcrumbs,
  ...previdenciaBreadcrumbs,
  ...contratosPrestamistaBreadcrumbs,
  ...prestamistaBreadcrumbs,
  ...portabilidadeBreadcrumbs,
  ...vidaBreadcrumbs,
  ...ferramentasBreadcrumbs,
  ...consultaClienteBreadcrumbs,
  ...sinistroBreadcrumbs,
  ...relatoriosBreadcrumbs,
  ...extranetBreadcrumbs,
  ...atendimentoBreadcrumbs,
  ...registrarOcorrenciasAscBreadcrumbs,
  ...faturamentoBreadcrumbs,
  ...prospeccaoBreadcrumbs,
  ...painelPortabilidadeBreadcrumbs,
  ...painelDPSBreadcrumbs,
};

export default breadcrumbs;
