import { lazy } from 'react';

const RotasMain = lazy(() => import('main/config/RotasMain'));
const RotasPrevidencia = lazy(() => import('previdencia/config/routes'));
const RotasVida = lazy(() => import('vida/config/routes'));
const RotasFerramentas = lazy(() => import('ferramentas/config/routes'));
const RotasPrestamista = lazy(() => import('prestamista/config/routes'));
const RotasProspeccao = lazy(
  () => import('contratosPrestamista/features/preanalise/routes'),
);

const RotasPainelPortabilidade = lazy(
  () => import('painelPortabilidade/config/routes'),
);

const RotasPortabilidade = lazy(() => import('portabilidade/config/routes'));
const RotasConsultaCliente = lazy(
  () => import('consultaCliente/config/routes'),
);
const RotasContratosPrestamista = lazy(
  () => import('contratosPrestamista/config/routes'),
);
const RotasStatusVendas = lazy(
  () => import('consultaStatusVendas/config/routes'),
);
const RotasSinistro = lazy(() => import('sinistro/config/routes'));
const RotasReter = lazy(() => import('reter/config/routes'));
const RotasRelatorios = lazy(() => import('relatorios/config/routes'));
const RotasAtendimento = lazy(() => import('atendimento/config/routes'));
const RotasExtranet = lazy(() => import('extranet/config/routes'));
const RotasRegistrarOcorrenciasAsc = lazy(
  () => import('registroOcorrenciaASC/config/routes'),
);
const RotasInadinplenciaVidaPu = lazy(
  () => import('painelVidaPu/config/RotasInadinplenciaVidaPu'),
);

const RotasPrestamistaDeclinio = lazy(
  () => import('painelPrestamistaDeclinio/config/RotasPrestamistaDeclinio'),
);

const RotasPainelResgate = lazy(
  () => import('painelResgate/config/RotasPainelResgate'),
);
const RotasInadimplencia = lazy(
  () => import('painelInadimplencia/config/routes'),
);

const RotasDPS = lazy(() => import('dps/config/routes'));

const RotasPainelPrestamista = lazy(
  () => import('painelPrestamista/config/RotasPainelPrestamista'),
);

const RotasPropostaVida = lazy(() => import('propostasVida/config/routes'));

export {
  RotasMain,
  RotasPrevidencia,
  RotasVida,
  RotasFerramentas,
  RotasPortabilidade,
  RotasConsultaCliente,
  RotasContratosPrestamista,
  RotasStatusVendas,
  RotasSinistro,
  RotasReter,
  RotasRelatorios,
  RotasAtendimento,
  RotasExtranet,
  RotasRegistrarOcorrenciasAsc,
  RotasPainelPortabilidade,
  RotasInadimplencia,
  RotasPrestamista,
  RotasInadinplenciaVidaPu,
  RotasPainelResgate,
  RotasPrestamistaDeclinio,
  RotasPainelPrestamista,
  RotasProspeccao,
  RotasPropostaVida,
  RotasDPS,
};
